import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Auth } from '@supabase/auth-ui-react';
import { supabase } from '@/lib/supabase';
import { supabaseAuthAppearance } from '@/styles/supabase-appearance';
import { buttonVariants } from '../ui/button';
import { Triggers } from '@/stores/my';
import { useAuthMenu } from '@/lib/hooks/use-auth-menu';
import { linkColor } from '@/styles/links';
import { Anchor } from '../ui/anchor';

// auth dropdown menu content
export const AuthDropdownMarketingMenu = () => {
  const { $session, setTrigger, setOpen, open, trigger } = useAuthMenu();

  const onLoginEvent = () => setTrigger(Triggers.login);
  const onRegisterEvent = () => setTrigger(Triggers.register);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {$session ? (
        <Anchor className={buttonVariants()} href={'/dashboard'}>
          Dashboard
        </Anchor>
      ) : (
        <div className={`flex gap-3 items-center transition`}>
          <DialogTrigger
            onClick={onLoginEvent}
            className={`${buttonVariants({
              variant: 'default',
            })} uppercase font-mono bg-black text-white dark:bg-white dark:text-black`}
          >
            <span>Sign In</span>
          </DialogTrigger>
          <DialogTrigger
            onClick={onRegisterEvent}
            className={`${buttonVariants({
              variant: 'outline',
            })} uppercase font-mono dark:border-white border-black`}
          >
            <span>Register</span>
          </DialogTrigger>
        </div>
      )}

      <DialogContent className="sm:max-w-[425px]">
        <div className="max-w-screen-sm container text-black dark:text-white">
          <DialogHeader className="sr-only">
            <DialogTitle className="capitalize text-center">Spider</DialogTitle>
            <DialogDescription className="text-center">Authenticate to start.</DialogDescription>
          </DialogHeader>
          <Auth
            supabaseClient={supabase}
            view={trigger === Triggers.register ? 'sign_up' : undefined}
            appearance={supabaseAuthAppearance}
            providers={['github', 'discord']}
            localization={{
              variables: {
                sign_up: {
                  password_label: 'Password',
                  button_label: 'Create account',
                },
                sign_in: {
                  password_label: 'Password',
                  button_label: 'Log in',
                },
              },
            }}
          />
        </div>
        <div className="flex place-content-center items-center gap-3 py-2 text-muted-foreground text-sm justify-center">
          <Anchor className={linkColor} href={'/privacy'} target="__blank">
            Privacy Policy
          </Anchor>
          <span className="text-xs">|</span>
          <Anchor className={linkColor} href={'/eula'} target="__blank">
            Terms of Use
          </Anchor>
        </div>
      </DialogContent>
    </Dialog>
  );
};
